import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    greenLogo: {
      100: '#f7fafc',
      400: '#02e269',
      700: '#7fb97a',
      900: '#586457',
    },
    gradient: {
      100: 'linear-gradient(90deg, rgba(127,185,122,0.9) 0%, rgba(65,64,116,0.9) 100%)',
      200: 'linear-gradient(90deg, rgba(127,185,122,1) 0%, rgba(65,64,116,1) 100%)',
    },
    blueLogo: {
      100: '#508de7',
      400: '#546ada',
      700: '#414074',
      900: '#051b34',
    },
    coralLogo: '#ff8989',
    // surface: '#8693a3', Keep for later use
    // surface: '#fffbf5',
    // surface: '#fcfdff',
    surface: 'rgb(249, 250, 252)',
    // header: '#414074',
    header: '#111518',
    footer: '#111518',
  },
  styles: {
    global: {
      header: {
        bg: '#111518',
      },
      footer: {
        bg: '#111518',
      },
    },
  },
  fonts: {
    body: "'Segoe UI', sans-serif",
  },
  components: {
    Text: {
      baseStyle: {
        color: '#ffffff',
        fontFamily: "'Segoe UI', sans-serif",
      },
    },
    Button: {
      baseStyle: {
        color: '#ffffff',
        fontFamily: "'Segoe UI', sans-serif",
      },
    },
  },
});
