import React, { useRef, useEffect } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';

import SectionsContext from '../resources/SectionsContext';
import { HEADERS } from '../resources/constants';
import { COLORS } from '../resources/colors';
import CoreTitle from './core/CoreTitle';
import CoreButton from './core/CoreButton';
import { useOnScreen } from '../hooks/onScreen';
import AcademyLogo from '../ns-academy-logo.svg';

const Home = () => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const { scrollTo } = React.useContext(SectionsContext);

  useEffect(() => {
    const headerLogo = document.getElementById('headerContainer');

    if (isVisible) {
      headerLogo.style.opacity = 0;
    } else {
      headerLogo.style.opacity = 1;
    }
  }, [isVisible]);

  return (
    <div ref={ref}>
      <Flex
        position="relative"
        height="0"
        direction={{ base: 'row', sm: 'row', md: 'row-reverse' }}
        justifyContent={{
          base: 'center',
          sm: 'center',
          md: 'unset',
        }}
      >
        <Image src={AcademyLogo} alt="academy-logo" height="120px" mt="6" />
      </Flex>
      <Flex direction="column">
        <Box className="section" mt="110px" minH="850px">
          <Flex
            direction="column"
            justifyContent={'center'}
            width="90%"
            gap={36}
          >
            <Flex
              flexDir="column"
              width={{
                base: '100%',
                sm: '100%',
                md: '68%',
                lg: '68%',
                xl: '68%',
                '2xl': '68%',
              }}
              gap={16}
            >
              <CoreTitle
                text="Cunoașterea este cea mai înțeleaptă investiție pe care o persoană o poate face."
                textAlign="left"
              />

              <Flex
                flexDir={{
                  base: 'column',
                  sm: 'row',
                  md: 'row',
                  lg: 'row',
                  xl: 'row',
                  '2xl': 'row',
                }}
                gap="8"
              >
                <CoreButton
                  text="CURSURI"
                  onClick={() => scrollTo(HEADERS.trainingPrograms)}
                  bg={COLORS.darkBlue}
                  minWidth="170px"
                  height="70px"
                />

                <CoreButton
                  text="APLICĂ ACUM"
                  onClick={() => scrollTo(HEADERS.joinUs)}
                  bg={COLORS.green}
                  minWidth="220px"
                  height="70px"
                />
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </div>
  );
};

export default Home;
