import React from 'react';

import AboutUs from '../components/about-us';
import Benefits from '../components/benefits';
import Home from '../components/home';
import JoinUs from '../components/join-us';
import OurMentors from '../components/our-mentors';
import RecruitmentCircuit from '../components/recruitment-circuit';
import TrainingPrograms from '../components/training-programs';
import { HEADERS } from '../resources/constants';

const COMPONENTS = {
  [HEADERS.home]: Home,
  [HEADERS.aboutUs]: AboutUs,
  [HEADERS.trainingPrograms]: TrainingPrograms,
  [HEADERS.recruitmentCircuit]: RecruitmentCircuit,
  [HEADERS.benefits]: Benefits,
  [HEADERS.ourMentors]: OurMentors,
  [HEADERS.joinUs]: JoinUs,
};

const HomePage = () => {
  return (
    <>
      {Object.keys(COMPONENTS).map((key, index) => {
        const Section = COMPONENTS[key];
        return <Section key={`${key}-${index}`} />;
      })}
    </>
  );
};

export default HomePage;
