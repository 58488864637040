import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ROUTES from '../resources/app-routes';
import HomePage from './home-page';
import PrivacyPolicy from '../components/privacy-policy';
import TermsAndConditions from '../components/terms-and-conditions';
import Header from './header';
import NotFound from '../components/not-found';
import academyGif from '../resources/background/academyGif.gif';

const BodyContent = () => {
  return (
    <>
      <BrowserRouter>
        <img
          alt="academyGif"
          src={academyGif}
          style={{
            position: 'fixed',
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            zIndex: -1,
            top: 0,
            left: 0,
          }}
        />

        <Header />

        <Switch>
          <Route exact path={ROUTES.home}>
            <HomePage />
          </Route>

          <Route exact path={ROUTES.privacyPolicy}>
            <PrivacyPolicy />
          </Route>

          <Route exact path={ROUTES.termsAndConditions}>
            <TermsAndConditions />
          </Route>

          <Route>
            <NotFound />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default BodyContent;
