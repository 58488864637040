import React from 'react';
import SectionsContext from '../../resources/SectionsContext';
import SectionContainerCore from './SectionContainerCore';

const SectionContainer = ({
  children,
  sectionName,
  fullWidth,
  styleProps = {},
}) => {
  const sectionRef = React.useRef(null);
  const { linkSectionRef } = React.useContext(SectionsContext);

  React.useEffect(() => {
    linkSectionRef(sectionRef, sectionName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionContainerCore
      refObj={sectionRef} // Must be passed as a different prop
      fullWidth={fullWidth}
      {...(styleProps || {})}
    >
      {children}
    </SectionContainerCore>
  );
};

export default SectionContainer;
