import React from 'react';
import { Button } from '@chakra-ui/react';

const CoreButton = ({
  text,
  onClick,
  fontSize = '24px',
  borderRadius = '40px',
  ...styleProps
}) => {
  return (
    <Button
      onClick={onClick}
      fontSize={fontSize}
      borderRadius={borderRadius}
      {...(styleProps || {})}
    >
      {text}
    </Button>
  );
};

export default CoreButton;
