import React, { useRef, useEffect } from 'react';

import { Text, ListItem, UnorderedList, Box } from '@chakra-ui/react';

import SectionContainerCore from './core/SectionContainerCore';
import { useOnScreen } from '../hooks/onScreen';

const PARAGRAPH_STYLE = {
  textAlign: 'justify',
  style: { textIndent: '40px' },
  fontSize: 'md',
  mb: '4',
};

const TITLE_STYLE = {
  textAlign: 'left',
  fontSize: 'xl',
  mb: '4',
  mt: '10',
  fontWeight: 'bold',
};

const SUBTITLE_STYLE = {
  textAlign: 'left',
  fontSize: 'lg',
  mb: '4',
};

const Paragraph = ({ children }) => {
  return <Text {...PARAGRAPH_STYLE}>{children}</Text>;
};

const Title = ({ children }) => {
  return <Text {...TITLE_STYLE}>{children}</Text>;
};

const Subtitle = ({ children }) => {
  return <Text {...SUBTITLE_STYLE}>{children}</Text>;
};

const PrivacyPolicy = () => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    const headerLogo = document.getElementById('headerContainer');

    if (isVisible) {
      headerLogo.style.opacity = 1;
    }
  }, [isVisible]);

  return (
    <div ref={ref}>
      <SectionContainerCore>
        <Box className="section" display="flex" flexDirection="column">
          <Text fontSize="2xl" textAlign="left" mb="6">
            Politica de confidențialitate – Nenos Academy
          </Text>

          <Paragraph>
            Această politică a fost actualizată ultima dată pe 5 aprilie 2022.
          </Paragraph>
          <Paragraph>
            Nenos Software SRL prin intermediul Nenos Academy („Nenos Software",
            „Nenos Academy”,„noi", „al nostru" sau „nouă") se angajează să vă
            protejeze confidențialitatea. Politica noastră de confidențialitate
            („Politica") explică modul în care colectăm, utilizăm, dezvăluim și
            protejăm informațiile despre dumneavoastra sau asociate cu
            dumneavoastra („informații personale") și alegerile dumneavoastra cu
            privire la colectarea și utilizarea informațiilor dumneavoastra.
            Această Politică de confidențialitate se aplică serviciilor online
            oferite de Nenos Software, cum ar fi site-ul nostru web
            www.nenos-academy.com și orice altă locație de servicii online
            (denumite colectiv „Serviciul") care vă pune la dispoziție această
            Politică de confidențialitate.
          </Paragraph>

          <Paragraph>
            Prin utilizarea Serviciului, sunteți de acord cu Termenii și
            condițiile noastre și sunteți de acord cu practicile noastre de
            colectare, utilizare și divulgare și alte activități descrise în
            această Politică de confidențialitate. Dacă nu sunteți de acord,
            întrerupeți utilizarea Serviciului.
          </Paragraph>

          <Paragraph>
            Dacă aveți întrebări sau doriți să vă exercitați drepturile și
            opțiunile, vă rugăm să ne contactați așa cum se prevede în secțiunea
            de contact.
          </Paragraph>

          <Title>Colectarea informațiilor</Title>

          <Paragraph>
            Această secțiune va exemplifica ce tip de informații sunt colectate.
            Aceasta include categorii de informații pe care (1) ni le furnizați
            direct, (2) sunt colectate automat de noi și (3) le primim din surse
            terțe.
          </Paragraph>

          <Subtitle>1. Informații pe care ni le furnizați direct</Subtitle>
          <Paragraph>
            Puteți naviga pe site-ul nostru fără a furniza informații personale,
            dar trebuie să vă înregistrați pentru a accesa majoritatea
            funcțiilor Serviciului. În cazul în care alegeți să trimiteți în
            mesaje prin secțiunea „Alăturați-vă”, conținutul acestor mesaje
            poate fi procesat și salvat de noi. Puteți alege să ne furnizați în
            mod voluntar alte informații pe care nu le solicităm și, în astfel
            de cazuri, sunteți singurul responsabil pentru astfel de informații.
          </Paragraph>
          <Paragraph>
            Următoarele sunt categorii de informații pe care le colectăm și
            le-am colectat direct de la dvs. în ultimele 12 luni:
          </Paragraph>

          <UnorderedList mb="4" textColor="white">
            <ListItem textAlign="left" fontSize="md">
              <b>Date de contact</b>, inclusiv numele și prenumele, adresa de
              e-mail, adresa poștală și numărul de telefon.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Date demografice</b>, inclusiv vârsta, sexul și țara.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Datele de profil</b>, inclusiv interesele, deducțiile,
              preferințele și preferințele dvs.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Conținut</b>, inclusiv conținut din orice mesaje pe care ni le
              trimiteți (cum ar fi feedback și întrebări către asistența pentru
              clienți) sau postate public în cadrul Serviciului (cum ar fi în
              profilul dvs. public, în recenzii și comentarii), precum și orice
              conținut academic pe care îl generați și îl trimiteți pentru
              evaluare. De asemenea, colectăm conținut în cadrul oricăror mesaje
              pe care le schimbați cu alți utilizatori prin intermediul
              Serviciului (cum ar fi dacă utilizați funcționalitățile noastre de
              chat).
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Datele personale de contact</b>,inclusiv numele și prenumele,
              adresa de e-mail și numărul de telefon al contactelor dvs.
              personale.
            </ListItem>
          </UnorderedList>

          <Paragraph>
            Puteți alege să ne furnizați în mod voluntar alte informații și, în
            astfel de cazuri, sunteți singurul responsabil pentru astfel de
            informații.
          </Paragraph>

          <Subtitle>2. Informații colectate automat</Subtitle>
          <Paragraph>
            În plus, colectăm automat informații atunci când utilizați
            Serviciul. Categoriile de informații pe care le colectăm automat și
            le-am colectat în ultimele 12 luni includ:
          </Paragraph>

          <UnorderedList mb="4" textColor="white">
            <ListItem textAlign="left" fontSize="md">
              <b>Date privind utilizarea serviciilor</b>, inclusiv date despre
              caracteristicile pe care le utilizați, paginile pe care le
              vizitați, e-mailurile și reclamele pe care le vizualizați,
              produsele și serviciile pe care le vizualizați și le
              achiziționați, ora din zi în care navigați și paginile de
              referință și de ieșire.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Date despre dispozitiv</b>, inclusiv date despre tipul de
              dispozitiv sau browser pe care îl utilizați, software-ul de
              operare al dispozitivului, furnizorul de servicii de internet,
              setările regionale și lingvistice ale dispozitivului și
              identificatorii dispozitivului, cum ar fi adresa IP și ID-ul
              anunțului.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Date despre locație</b>, , inclusiv date imprecise despre
              locație (cum ar fi locația derivată dintr-o adresă IP sau date
              care indică un oraș sau un nivel de cod poștal) și, cu
              consimțământul dvs., date precise despre locație (cum ar fi date
              despre latitudine/longitudine).
            </ListItem>
          </UnorderedList>

          <Subtitle>3. Informații din alte surse</Subtitle>
          <Paragraph>
            De asemenea, colectăm informații din alte surse. Categoriile de
            surse din care colectăm informații, inclusiv în ultimele 12 luni,
            includ:
          </Paragraph>

          <UnorderedList mb="4" textColor="white">
            <ListItem textAlign="left" fontSize="md">
              Brokerii de date sau revânzătorii de la care achiziționăm date
              pentru a completa datele pe care le colectăm.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Rețele sociale atunci când interacționați cu conținutul nostru,
              faceți referire la Serviciul nostru sau ne acordați permisiunea de
              a accesa informații din rețelele sociale.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Parteneri care oferă servicii co-branded, vând sau distribuie
              produsele noastre sau se angajează în activități comune de
              marketing.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Surse disponibile publicului, inclusiv date din domeniul public.
            </ListItem>
          </UnorderedList>

          <Title>Utilizarea informațiilor</Title>
          <Paragraph>
            Această secțiune prezintă modul în care informațiile colectate sunt
            utilizate de Nenos Academy.
          </Paragraph>
          <Paragraph>
            Colectăm aceste informații pentru o serie de scopuri comerciale,
            inclusiv pentru a opera, întreține și furniza caracteristicile și
            funcționalitatea Serviciului, precum și pentru a comunica direct cu
            dvs., cum ar fi pentru a vă trimite mesaje de e-mail.
          </Paragraph>
          <Paragraph>
            Categoriile cu privire la scopurile noastre de afaceri pentru
            utilizarea informațiilor, inclusiv în ultimele 12 luni, includ:
          </Paragraph>

          <UnorderedList mb="4" textColor="white">
            <ListItem textAlign="left" fontSize="md">
              Operați și gestionați Serviciul nostru, inclusiv aplicarea dvs. la
              cursul nostru.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Pentru a efectua serviciile solicitate de dvs., cum ar fi pentru a
              răspunde la comentariile, întrebările și solicitările dvs. și
              pentru a furniza servicii pentru clienți.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Pentru a vă trimite notificări tehnice, actualizări, alerte de
              securitate, informații privind modificările aduse politicilor
              noastre și mesaje de asistență și administrative.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Prevenirea și combaterea fraudei, a încălcării politicilor sau
              termenilor și a amenințărilor sau daunelor.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Monitorizarea și analizarea tendințelor, utilizării și
              activităților.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Efectuarea de cercetări, inclusiv focus grupuri și sondaje.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Îmbunătățirea Serviciului sau a altor site-uri web, aplicații,
              eforturi de marketing, produse și servicii ale Nenos Academy.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Dezvoltarea și trimiterea de marketing direct, inclusiv reclame și
              comunicări despre produsele, ofertele, promoțiile, recompensele,
              evenimentele și serviciile noastre și ale altor entități.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Desfășurarea promoțiilor, inclusiv verificarea eligibilității și
              livrarea premiilor la finalul academiei parcurse de dvs.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Pentru a vă trimite publicitate.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Îndeplinirea oricăror alte scopuri comerciale sau comerciale la
              indicația dvs. sau cu notificarea și / sau consimțământul dvs.
            </ListItem>
          </UnorderedList>

          <Paragraph>
            Fără a implica aspectele mentionațe mai sus, putem utiliza
            informații care nu vă identifică direct (inclusiv informații care au
            fost agregate sau anonimizate) în orice scop, cu excepția cazurilor
            interzise de legea aplicabilă. Pentru informații despre drepturile
            și opțiunile dvs. cu privire la modul în care folosim informațiile
            despre dvs., consultați secțiunea „Drepturile și opțiunile dvs." de
            mai jos.
          </Paragraph>

          <Title>Schimbul de informații</Title>
          <Paragraph>
            Această secțiune explică practicile noastre de partajare a
            informațiilor colectate.
          </Paragraph>
          <Paragraph>
            Partajăm informațiile pe care le colectăm în conformitate cu
            practicile descrise în această politică de confidențialitate.
            Categoriile de entități cărora le dezvăluim și cărora le-am partajat
            informații în ultimele 12 luni includ următoarele:
          </Paragraph>

          <UnorderedList mb="4" textColor="white">
            <ListItem textAlign="left" fontSize="md">
              <b>Furnizori de servicii</b>. Partajăm informații cu entități care
              procesează informații în numele nostru în scopurile noastre de
              afaceri. Furnizorii de servicii ne ajută cu servicii precum
              procesarea plăților, funcționalitatea chat-ului, analiza datelor,
              marketing și publicitate, găzduirea site-urilor web și asistența
              tehnică. Putem permite furnizorilor noștri de servicii să
              utilizeze informații care nu vă identifică (inclusiv informații
              care au fost agregate sau anonimizate) în orice scop, cu excepția
              cazurilor interzise de legea aplicabilă.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Afiliate</b>. Partajăm informații cu entitățile noastre
              afiliate, inclusiv companiile mamă și surori, în scopuri
              comerciale, cum ar fi asistența pentru clienți, marketingul și
              operațiunile tehnice. De asemenea, putem partaja informații cu
              afiliații în scopuri comerciale.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Parteneri</b>. Partajăm informații cu partenerii noștri (cum ar
              fi bootcamp-uri, organizații de pregătire a interviurilor și piețe
              de ocupare a forței de muncă) în legătură cu oferirea de servicii
              co-branded, vânzarea sau distribuirea produselor noastre sau
              implicarea în activități comune de marketing.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Informații publice</b>. Împărtășim informațiile pe care le
              faceți publice prin intermediul Serviciului, cum ar fi
              informațiile din profilul dvs. sau pe care le postați pe panouri
              publice sau alte secțiuni publice ale Serviciului. Vă rugăm să vă
              gândiți cu atenție înainte de a face publice informațiile,
              deoarece sunteți singurul responsabil pentru orice informație pe
              care o faceți publică. După ce ați postat informații, este posibil
              să nu le puteți edita sau șterge, sub rezerva drepturilor
              suplimentare stabilite în secțiunea „Drepturile și opțiunile dvs."
              de mai jos.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Fuziune sau achiziție</b>. Partajăm informații în legătură cu
              sau în timpul negocierilor oricărei fuziuni, achiziții, vânzări
              sau orice alt tip de achiziție sau combinare de afaceri a tuturor
              sau a oricărei părți a activelor noastre sau transferul întregii
              sau unei părți din afacerea noastră către o altă afacere.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Securitate și divulgare forțată</b>. Partajăm informații pentru
              a respecta legea sau alte procese legale și, dacă este necesar, ca
              răspuns la solicitările legale ale autorităților publice, inclusiv
              pentru a îndeplini cerințele de securitate națională sau de
              aplicare a legii. De asemenea, împărtășim informații pentru a
              proteja drepturile, proprietatea, viața, sănătatea, securitatea și
              siguranța noastră, a Serviciului sau a oricui altcuiva.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Facilitarea solicitărilor</b>. Partajăm informații la cererea
              sau îndrumarea dvs., cum ar fi atunci când alegeți să partajați
              informații cu o rețea socială despre activitățile dvs. în cadrul
              Serviciului.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Consimțământ</b>. Partajăm informații cu notificarea dvs. și
              consimțământul dvs.
            </ListItem>
          </UnorderedList>

          <Paragraph>
            Fără a implica aspectele mentionațe mai sus, putem partaja
            informații care nu vă identifică (inclusiv informații care au fost
            agregate sau anonimizate), cu excepția cazurilor interzise de legea
            aplicabilă. Pentru informații despre drepturile și opțiunile dvs. cu
            privire la modul în care partajăm informații despre dvs., consultați
            secțiunea „Drepturile și opțiunile dvs." de mai jos.
          </Paragraph>

          <Title>Social Media și Integrări tehnologice</Title>
          <Paragraph>
            Oferim părți ale Serviciului nostru prin intermediul site-urilor
            web, platformelor și serviciilor operate sau controlate de entități
            separate. În plus, integrăm tehnologii operate sau controlate de
            entități separate în părți ale Serviciului nostru. Câteva exemple
            includ:
          </Paragraph>
          <UnorderedList mb="4" textColor="white">
            <ListItem textAlign="left" fontSize="md">
              <b>Legături</b>. Serviciul nostru include link-uri care fac
              hyperlink către site-uri web, platforme și alte servicii care nu
              sunt operate sau controlate de noi.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Pagini web și chatbots</b>. Putem oferi conținutul nostru prin
              intermediul rețelelor sociale. Orice informație pe care ne-o
              furnizați atunci când interacționați cu conținutul nostru (cum ar
              fi prin intermediul paginii noastre de social media sau prin
              chatbot-ul nostru de pe Facebook Messenger) este tratată în
              conformitate cu această politică de confidențialitate. De
              asemenea, dacă faceți referire publică la Serviciul nostru pe
              rețelele sociale (de exemplu, utilizând un hashtag asociat cu
              Nenos Academy într-un tweet sau postare), este posibil să folosim
              referința dvs. pe sau în legătură cu Serviciul nostru.
            </ListItem>
          </UnorderedList>

          <Paragraph>
            Vă rugăm să rețineți că atunci când interacționați cu alte entități,
            inclusiv atunci când părăsiți Serviciul nostru, acele entități pot
            colecta în mod independent informații despre dvs. și pot solicita
            informații de la dvs. Informațiile colectate și stocate de aceste
            entități rămân supuse propriilor politici și practici, inclusiv
            informațiile pe care le partajează cu noi, drepturile și opțiunile
            dvs. cu privire la serviciile și dispozitivele lor și dacă stochează
            informații în S.U.A. sau în altă parte. Vă încurajăm să vă
            familiarizați și să consultați politicile de confidențialitate și
            termenii de utilizare.
          </Paragraph>

          <Title>Analiză și publicitate</Title>
          <Paragraph>
            Utilizăm servicii de analiză, cum ar fi Google Analytics, pentru a
            ne ajuta să înțelegem modul în care utilizatorii accesează și
            utilizează Serviciul. În plus, colaborăm cu agenții, agenți de
            publicitate, rețele publicitare și alte servicii tehnologice pentru
            a plasa reclame despre produsele și serviciile noastre pe alte
            site-uri web și servicii. De exemplu, plasăm anunțuri prin Google și
            Facebook pe care le puteți vizualiza pe platformele lor, precum și
            pe alte site-uri web și servicii.
          </Paragraph>
          <Paragraph>
            Ca parte a acestui proces, putem încorpora tehnologii de urmărire în
            propriul nostru Serviciu (inclusiv site-ul nostru web și
            e-mailurile), precum și în anunțurile noastre afișate pe alte
            site-uri web și servicii. Unele dintre aceste tehnologii de urmărire
            vă pot urmări activitățile de-a lungul timpului și serviciile în
            scopul asocierii diferitelor dispozitive pe care le utilizați și al
            furnizării de reclame relevante și/sau alt conținut („Publicitate
            bazată pe interese").
          </Paragraph>
          <Paragraph>
            După cum s-a indicat mai sus, furnizorii pot acționa ca furnizori de
            servicii sau, în anumite contexte, pot decide în mod independent cum
            să proceseze informațiile dvs. Vă încurajăm să vă familiarizați și
            să consultați politicile de confidențialitate și termenii de
            utilizare.
          </Paragraph>
          <Paragraph>
            Pentru informații suplimentare despre tipurile de tehnologii de
            urmărire pe care le folosim în cadrul Serviciului și despre
            drepturile și opțiunile dvs. privind analiza și publicitatea bazată
            pe interese, consultați secțiunile "Informații colectate automat" și
            „Drepturile și opțiunile dvs.".
          </Paragraph>

          <Title>Drepturile și opțiunile dvs.</Title>
          <Paragraph>
            Aveți drepturi și opțiuni cu privire la informațiile care vă
            privesc, însă aceste drepturi vor varia în funcție de categoria de
            informații și de utilizarea ulterioară a acestor informații. Această
            secțiune descrie în continuare drepturile dumneavoastră.
          </Paragraph>
          <Subtitle>
            <b>Comunicare.</b>
          </Subtitle>
          <UnorderedList mb="4" textColor="white">
            <ListItem textAlign="left" fontSize="md">
              <b>E-mailuri</b>. Puteți renunța la primirea de e-mailuri
              promoționale de la noi în orice moment, urmând instrucțiunile
              furnizate în e-mailuri pentru a face clic pe linkul de dezabonare
              sau contactându-ne așa cum se prevede în secțiunea „Contactați-ne"
              de mai jos. Vă rugăm să rețineți că nu puteți renunța la
              e-mailurile nepromoționale, cum ar fi cele despre contul dvs.,
              tranzacții, servicii sau relațiile noastre de afaceri în curs de
              desfășurare.
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              <b>Notificări push</b>. Dacă ați optat să primiți notificări push
              pe dispozitivul dvs., puteți renunța în orice moment ajustând
              permisiunile din dispozitiv sau dezinstalând aplicația noastră.
            </ListItem>
          </UnorderedList>
          <Paragraph>
            Vă rugăm să rețineți că renunțarea este limitată la adresa de e-mail
            și la dispozitivul utilizat și nu va afecta abonamentele ulterioare.
          </Paragraph>

          <Title>Securitatea datelor</Title>
          <Paragraph>
            Nenos Academy utilizează măsuri de securitate administrative,
            tehnice și fizice rezonabile din punct de vedere comercial,
            concepute pentru a păstra integritatea și securitatea tuturor
            informațiilor colectate prin intermediul Serviciului. Cu toate
            acestea, niciun sistem de securitate nu este impenetrabil și nu
            putem garanta 100% securitatea sistemelor noastre. În cazul în care
            orice informație aflată sub controlul nostru este compromisă ca
            urmare a unei încălcări a securității, vom lua măsuri rezonabile și
            adecvate pentru a investiga situația, vom notifica acele persoane
            ale căror informații ar fi putut fi compromise în conformitate cu
            orice legi și reglementări aplicabile și vom lua alte măsuri, după
            caz. Setările dvs. de confidențialitate pot fi, de asemenea,
            afectate de modificările funcționalității site-urilor și serviciilor
            terțe pe care le adăugați la Serviciul Nenos Academy, cum ar fi
            rețelele sociale. Nenos Academy nu este responsabilă pentru
            funcționalitatea sau măsurile de securitate ale niciunei terțe
            părți.
          </Paragraph>

          <Title>Modificări ale acestei Politici de confidențialitate</Title>
          <Paragraph>
            Nenos Academy își rezervă dreptul de a modifica sau actualiza
            această politică de confidențialitate din când în când pentru a
            reflecta schimbările din afacerea și practicile noastre și, prin
            urmare, ar trebui să revizuiți periodic această pagină. Când
            modificăm politica, vom reflecta data "ultimei modificări" în partea
            de sus a acestei Politici de confidențialitate.
          </Paragraph>

          <Title>Link-uri către alte site-uri web și servicii</Title>
          <Paragraph>
            Serviciile pot conține, din când în când, linkuri către și de la
            site-uri web, platforme și alte servicii care nu sunt operate sau
            controlate de noi. Dacă urmați un link către oricare dintre aceste
            locații, rețineți că aceste locații au propriile politici de
            confidențialitate și că nu acceptăm nicio responsabilitate sau
            răspundere pentru politicile lor. Verificați politicile individuale
            înainte de a trimite informații către locațiile respective.
          </Paragraph>

          <Title>Contactează-ne</Title>
          <Paragraph>
            Dacă aveți întrebări cu privire la această politică de
            confidențialitate, practicile noastre privind datele, conformitatea
            noastră cu legislația aplicabilă sau Serviciul, vă rugăm să ne
            contactați trimițând un e-mail la: <b>contact@nenos-academy.ro</b>
          </Paragraph>
        </Box>
      </SectionContainerCore>
    </div>
  );
};

export default PrivacyPolicy;
