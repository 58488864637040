import { Flex, Text, Link, Tooltip } from '@chakra-ui/react';
import React from 'react';

import MAIN_DATA from '../resources/app-data/main';
import ROUTES from '../resources/app-routes';

import OnePager from '../resources/one-pager/Nenos Academy_One pager.pdf';
import OnePagerIcon from '../resources/footer/onePager.png';
import FacebookIcon from '../resources/footer/facebook.png';
import InstagramIcon from '../resources/footer/instagram.png';
// import TweeterIcon from '../resources/footer/tweeter.png';
// import LinkedinIcon from '../resources/footer/linkedin.png';
// import RedditIcon from '../resources/footer/reddit.png';

const SOCIAL_MEDIA = {
  facebook: {
    icon: FacebookIcon,
    link: 'https://www.facebook.com/nenosacademy',
    styleProps: { width: '31px' },
  },
  instagram: {
    icon: InstagramIcon,
    link: 'https://www.instagram.com/nenosacademy',
    styleProps: { width: '31px' },
  },
  onePager: {
    icon: OnePagerIcon,
    link: OnePager,
    styleProps: { width: '28px' },
    tooltip: 'Download Nenos Academy One Pager',
  },
  // tweeter: {
  //   icon: TweeterIcon,
  //   link: '#', // TODO: Add
  //   styleProps: { width: '28px', marginLeft: '4px', marginRight: '4px' },
  // },
  // linkedin: {
  //   icon: LinkedinIcon,
  //   link: '#', // TODO: Add
  //   styleProps: { width: '27px', marginLeft: '2px', marginRight: '4px' },
  // },
  // reddit: {
  //   icon: RedditIcon,
  //   link: '#', // TODO: Add
  //   styleProps: { width: '32px' },
  // },
};

const FooterContent = () => {
  return (
    <Flex
      width="100%"
      height="350px"
      bg="footer"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Flex
        direction={{
          base: 'column-reverse',
          sm: 'column-reverse',
          md: 'row',
          lg: 'row',
          xl: 'row',
          '2xl': 'row',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          direction="column"
          alignItems="center"
          mr={{
            base: '0',
            sm: '0',
            md: '20',
            lg: '20',
            xl: '20',
            '2xl': '20',
          }}
        >
          <Text fontSize="2xl">Contact us</Text>
          <Text fontSize="xl" mt="6">
            Phone: {MAIN_DATA.phone}
          </Text>

          <Text fontSize="xl" mt="2">
            Email: {MAIN_DATA.email}
          </Text>

          <Flex mt="2" alignItems="center" justifyContent="center" gridGap={2}>
            {Object.keys(SOCIAL_MEDIA).map(mediaType =>
              SOCIAL_MEDIA[mediaType].tooltip ? (
                <Tooltip
                  key={mediaType}
                  hasArrow
                  label={SOCIAL_MEDIA[mediaType].tooltip}
                >
                  <a
                    href={SOCIAL_MEDIA[mediaType].link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={SOCIAL_MEDIA[mediaType].icon}
                      style={SOCIAL_MEDIA[mediaType].styleProps}
                      alt={mediaType}
                    />
                  </a>
                </Tooltip>
              ) : (
                <a
                  key={mediaType}
                  href={SOCIAL_MEDIA[mediaType].link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={SOCIAL_MEDIA[mediaType].icon}
                    style={SOCIAL_MEDIA[mediaType].styleProps}
                    alt={mediaType}
                  />
                </a>
              )
            )}
          </Flex>
        </Flex>

        <Flex
          direction="column"
          alignItems="center"
          ml={{
            base: '0',
            sm: '0',
            md: '20',
            lg: '20',
            xl: '20',
            '2xl': '20',
          }}
          mb={{
            base: '3',
            sm: '3',
            md: '0',
            lg: '0',
            xl: '0',
            '2xl': '0',
          }}
        >
          <Text fontSize="2xl">Policy</Text>
          <Text fontSize="xl" mt="6">
            <Link href={ROUTES.privacyPolicy} isExternal>
              Privacy Policy
            </Link>
          </Text>

          <Text fontSize="xl" mt="2">
            <Link href={ROUTES.termsAndConditions} isExternal>
              Terms And Conditions
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FooterContent;
