import React from 'react';
import { Box } from '@chakra-ui/react';

// import topWave from '../../resources/images/haikeiWaveTop.svg';
// import bottomWave from '../../resources/images/haikeiWaveBottom.svg';
import SectionContainerCore from './SectionContainerCore';

const FilledContainer = ({ children, containerProps = {} }) => {
  return (
    <>
      {/*  TODO: Keep for later use
       <AspectRatio ratio={960 / 100}>
        <Box
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundImage={topWave}
        /> bg="surface"
      </AspectRatio> */}

      <Box h="100%" w="100%" flex="1" {...containerProps}>
        <SectionContainerCore>{children}</SectionContainerCore>
      </Box>

      {/* <AspectRatio ratio={960 / 100}>
        <Box
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundImage={bottomWave}
        />
      </AspectRatio> */}
    </>
  );
};

export default FilledContainer;
