export default class ApiHelper {
  static joinUsSubmit(form = null) {
    let formData = new FormData();

    Object.keys(form).forEach(keyName => {
      formData.append(keyName, form[keyName] || '-');
    });

    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();

      xhr.open(
        'post',
        `${process.env.REACT_APP_BACKEND_URL}/backend/contact`,
        true
      );

      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhr.setRequestHeader(
        'Access-Control-Allow-Methods',
        'GET, POST, PATCH, PUT, DELETE, OPTIONS'
      );
      xhr.setRequestHeader(
        'Access-Control-Allow-Headers',
        'Origin, Content-Type, X-Auth-Token, Authorization, Lang'
      );

      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          let responseData = {};
          try {
            responseData = JSON.parse(xhr.responseText || '{}');
          } catch {}

          if (xhr.status !== 200) {
            reject(new Error('API call failed'));
            return;
          }

          resolve(responseData);
        }
      };

      xhr.addEventListener('error', function (e) {
        reject(new Error('Operation is in error'));
      });
      xhr.addEventListener('abort', function (e) {
        reject(new Error('Operation was aborted'));
      });

      xhr.send(formData);
    });
  }
}
