import React from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Button, Image, Box } from '@chakra-ui/react';

import AcademyLogo from '../ns-academy-logo.svg';
import SectionsContext from '../resources/SectionsContext';
import { HEADERS } from '../resources/constants';
import SectionContainerCore from '../components/core/SectionContainerCore';
import ROUTES from '../resources/app-routes';
import { COLORS } from '../resources/colors';

const BUTTON_STYLE = {
  display: {
    base: 'none',
    sm: 'none',
    md: 'none',
    lg: 'block',
    xl: 'block',
    '2xl': 'block',
  },
  fontSize: {
    base: 'none',
    sm: 'none',
    md: '16px',
    lg: '16px',
    xl: '16px',
    '2xl': '16px',
  },
  color: 'white',
  variant: 'link',
  fontWeight: 'bold',
  _focus: { boxShadow: 'none' },
};

const Header = () => {
  const history = useHistory();
  const { scrollTo } = React.useContext(SectionsContext);

  const isHome = window.location.pathname === ROUTES.home;

  const handleClick = key => {
    if (!isHome) {
      history.push(ROUTES.home);
      return;
    }

    scrollTo(key);
  };

  const handleClickLogo = () => {
    if (isHome) {
      return;
    }

    history.push(ROUTES.home);
  };

  return (
    <div
      id="headerContainer"
      style={{
        transition: 'visibility 0.2s linear,opacity 0.2s linear',
        opacity: 0,
      }}
    >
      <Flex
        width="100%"
        justifyContent="center"
        position="fixed"
        zIndex="5000"
        margin="0"
      >
        <SectionContainerCore
          zIndex="1"
          display="flex"
          direction="row"
          alignItems="center"
          pt="0"
          pb="0"
          height="90px"
          justifyContent={{
            base: 'center',
            sm: 'center',
            md: 'center',
            lg: 'space-between',
            xl: 'space-between',
            '2xl': 'space-between',
          }}
          width="100%"
          bg="rgb(17, 21, 24, 0.8)"
          fullWidth
          mr="4"
          margin="0"
        >
          <Box
            height="75px"
            onClick={handleClickLogo}
            _hover={{ cursor: !isHome ? 'pointer' : 'default' }}
          >
            <Image
              id="headerLogo"
              src={AcademyLogo}
              alt="academy-logo"
              height="inherit"
              alignSelf="center"
            />
          </Box>

          <Flex gridGap={10}>
            {!isHome ? (
              <Button onClick={handleClick} {...BUTTON_STYLE} mr="6">
                HOME
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => handleClick(HEADERS.aboutUs)}
                  {...BUTTON_STYLE}
                >
                  {HEADERS.aboutUs}
                </Button>

                <Button
                  onClick={() => handleClick(HEADERS.trainingPrograms)}
                  {...BUTTON_STYLE}
                >
                  {HEADERS.trainingPrograms}
                </Button>

                <Button
                  onClick={() => handleClick(HEADERS.recruitmentCircuit)}
                  {...BUTTON_STYLE}
                >
                  {HEADERS.recruitmentCircuit}
                </Button>

                <Button
                  onClick={() => handleClick(HEADERS.ourMentors)}
                  {...BUTTON_STYLE}
                >
                  {HEADERS.ourMentors}
                </Button>
                <Button
                  onClick={() => handleClick(HEADERS.joinUs)}
                  {...BUTTON_STYLE}
                  bg={COLORS.green}
                  mr="6"
                  padding="10px 15px"
                  borderRadius="40px"
                >
                  {HEADERS.joinUs}
                </Button>
              </>
            )}
          </Flex>
        </SectionContainerCore>
      </Flex>
    </div>
  );
};

export default Header;
