import React, { useRef, useEffect } from 'react';

import { Text, ListItem, UnorderedList, Box } from '@chakra-ui/react';

import SectionContainerCore from './core/SectionContainerCore';
import { useOnScreen } from '../hooks/onScreen';

const PARAGRAPH_STYLE = {
  textAlign: 'justify',
  style: { textIndent: '40px' },
  fontSize: 'md',
  mb: '4',
};

const TITLE_STYLE = {
  textAlign: 'left',
  fontSize: 'xl',
  mb: '4',
  mt: '10',
  fontWeight: 'bold',
};

const SUBTITLE_STYLE = {
  textAlign: 'left',
  fontSize: 'lg',
  mb: '4',
};

const Paragraph = ({ children }) => {
  return <Text {...PARAGRAPH_STYLE}>{children}</Text>;
};

const Title = ({ children }) => {
  return <Text {...TITLE_STYLE}>{children}</Text>;
};

const Subtitle = ({ children }) => {
  return <Text {...SUBTITLE_STYLE}>{children}</Text>;
};

const TermsAndConditions = () => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    const headerLogo = document.getElementById('headerContainer');

    if (isVisible) {
      headerLogo.style.opacity = 1;
    }
  }, [isVisible]);

  return (
    <div ref={ref}>
      <SectionContainerCore>
        <Box className="section" display="flex" flexDirection="column">
          <Text fontSize="2xl" textAlign="left" mb="6">
            Termeni și condiții
          </Text>

          <Paragraph>
            Site-ul www.nenos-academy.ro este o platformă online pe care Nenos
            Software o folosește pentru a comunica cu publicul. Accesul și
            utilizarea site-ului web www.nenos-academy.ro sunt supuse termenilor
            și condițiilor (denumite în continuare "Aviz juridic"). Acest
            website este proprietatea NENOS SOFTWARE SRL, companie IT cu sediul
            social în Galați, Bld. Marea Unire Nr. 15, Bl. U4, Spatiu Comercial
            2, Galați. Telefon: 0336 401 269. Adresa de e-mail:
            contact@nenos-software.ro .
          </Paragraph>

          <Paragraph>
            Prin accesarea și utilizarea site-ului web www.nenos-academy.ro,
            acceptați prin prezenta Avizul juridic. Reprezentanții firmei care
            dețin site-ul își rezervă dreptul de a modifica unilateral acest
            aviz juridic fără notificarea prealabilă a utilizatorilor site-ului.
            Utilizarea în continuare a site-ului web www.nenos-academy.ro
            conduce implicit la acceptarea avizului juridic astfel modificat. În
            acest sens, înainte de a accesa orice pagină conținută în
            www.nenos-academy.ro site-ului, utilizatorii au obligația de a citi
            Avizul legal, deoarece acesta poate fi modificat din când în când.
          </Paragraph>

          <Paragraph>
            Nenos Software SRL, a creat site-ul https://nenos-academy.ro/ pentru
            a-și informa personal toți clienții. Utilizarea acestui site este
            condiționată de respectarea condițiilor generale de acces și
            utilizare detaliate mai jos, precum și de respectarea tuturor
            legilor aplicabile. Atunci când accesați site-ul, când îl parcurgeți
            și îl utilizați, acceptați implicit și necondiționat condițiile
            generale detaliate mai jos, care prevalează asupra oricărui alt
            acord pe care l-ați putea avea cu Nenos Software SRL, cu toți
            membrii rețelei sale comerciale sau doar cu unii dintre aceștia.
          </Paragraph>

          <Paragraph>
            <b>CONȚINUT:</b>
          </Paragraph>
          <UnorderedList mb="4" textColor="white">
            <ListItem textAlign="left" fontSize="md">
              Colectarea și prelucrarea datelor cu caracter personal;
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Copyrights;
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Denumirea/marca comercială și coninutul site-ului web;
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Garanția și clauza de siguranță pentru utilizarea site-ului web;
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Legea aplicabilă;
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Diverse.
            </ListItem>
          </UnorderedList>

          <Subtitle>
            1. Colectarea și prelucrarea datelor cu caracter personal
          </Subtitle>
          <Paragraph>
            Datele cu caracter personal vor fi stocate în conformitate cu
            Regulamentul (UE) 2016/679 privind protecția persoanelor fizice în
            ceea ce privește prelucrarea datelor cu caracter personal și privind
            libera circulație a acestor date și de abrogare a Directivei
            95/46/CE (Regulamentul general privind protecția datelor) (denumit
            în continuare "Regulamentul") numai atunci când ne acordați dreptul
            de a le prelucra.
          </Paragraph>
          <Paragraph>
            Întrucât acordul dvs. cu privire la utilizarea datelor dvs.
            personale în scopurile menționate mai sus poate include și
            transmiterea acestora către partenerii noștri, datele colectate pot
            fi direcționate și către astfel de terți.
          </Paragraph>
          <Paragraph>
            Conform cerințelor prevăzute de Regulament, Nenos Software, în
            calitate de operator de date cu caracter personal, este obligat să
            gestioneze, în condiții de siguranță și numai pentru scopurile
            indicate, datele personale pe care ni le-ați furnizat despre
            dumneavoastră.
          </Paragraph>
          <Paragraph>
            Datele sunt colectate pentru a fi prelucrate în scopuri legate de
            produsele și serviciile oferite de firmă, cum ar fi contactarea dvs.
            (inclusiv prin poștă, e-mail, fax, mesaj text sau telefon) în
            legătură cu solicitarea lor de bunuri sau servicii. Informațiile
            înregistrate sunt destinate utilizării de către operator și pot fi
            dezvăluite numai următorilor destinatari: dvs., procesatorul și
            partenerii contractuali ai operatorului.
          </Paragraph>
          <Paragraph>
            <b>Drepturile dvs.</b>
          </Paragraph>
          <Paragraph>
            Regulamentul acordă persoanelor ale căror date cu caracter personal
            sunt prelucrate o serie de drepturi. Astfel, pe lângă drepturile
            existente în prezent, există și dreptul la portabilitatea datelor
            sau la ștergerea datelor. Vă prezentăm mai jos o scurtă descriere a
            drepturilor dumneavoastră:
          </Paragraph>
          <Paragraph>
            Dreptul de acces înseamnă că aveți dreptul de a obține de la noi
            confirmarea faptului că se prelucrează sau nu date cu caracter
            personal care vă privesc și, dacă este cazul, acces la datele cu
            caracter personal și informații despre modul în care sunt prelucrate
            datele.
          </Paragraph>
          <Paragraph>
            Dreptul la portabilitatea datelor se referă la (i) dreptul de a
            primi datele cu caracter personal într-un format structurat,
            utilizat în mod obișnuit și care poate fi citit automat și (ii)
            dreptul ca aceste date să fie transmise altui operator, acolo unde
            este fezabil din punct de vedere tehnic. Dreptul la opoziție se
            referă la dreptul de a se opune prelucrării datelor cu caracter
            personal atunci când o astfel de prelucrare este necesară pentru
            îndeplinirea unei sarcini îndeplinite în interes public sau privește
            un interes legitim al operatorului.
          </Paragraph>
          <Paragraph>
            Dreptul la rectificare privește corectarea, fără întârzieri
            nejustificate, a oricăror date cu caracter personal inexacte. O
            astfel de rectificare este notificată oricărui destinatar căruia i-a
            fost trimisă data, cu excepția cazului în care acest lucru se
            dovedește imposibil sau presupune eforturi disproporționate.
          </Paragraph>
          <Paragraph>
            Dreptul la ștergere înseamnă că aveți dreptul de a obține de la noi
            ștergerea datelor cu caracter personal care vă privesc, fără
            întârzieri nejustificate, în cazul în care se aplică unul dintre
            următoarele motive: acest lucru nu mai este necesar în legătură cu
            scopurile pentru care au fost colectate sau prelucrate în alt mod;
            vă retrageți consimțământul și nu există niciun alt temei juridic
            pentru prelucrare; vă opuneți prelucrării și nu există motive
            legitime imperative pentru prelucrare; datele cu caracter personal
            au fost prelucrate ilegal; datele cu caracter personal trebuie
            șterse pentru respectarea unei obligații legale; Datele cu caracter
            personal au fost colectate în legătură cu oferirea de servicii ale
            societății informaționale.
          </Paragraph>
          <Paragraph>
            Prin accesarea site-ului nostru web, pot fi prelucrate următoarele
            date, dar fără a se limita la acestea:
          </Paragraph>
          <UnorderedList mb="4" textColor="white">
            <ListItem textAlign="left" fontSize="md">
              Adresa IP a computerului utilizat în scopul accesului;
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Data și ora accesării sau solicitării;
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Numele paginii/fișierului accesat;
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Browserul utilizat de utilizator, inclusiv versiunea browserului;
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              Sistemul de operare utilizat de utilizator;
            </ListItem>
            <ListItem textAlign="left" fontSize="md">
              În acest context, sunt utilizate și cookie-uri.
            </ListItem>
          </UnorderedList>
          <Paragraph>
            Cookie-urile sunt fișiere text salvate pe computerul vizitatorului
            site-ului, permițând astfel recunoașterea unui utilizator anonim.
            Acest site web folosește cookie-uri pentru a executa un protocol mai
            precis de accesare a site-ului web. Puteți dezactiva cookie-urile de
            pe acest site web în orice moment.
          </Paragraph>
          <Paragraph>
            Vă rugăm să rețineți că, dacă dezactivați cookie-urile,
            funcționalitățile site-ului web pot fi restricționate.
          </Paragraph>

          <Subtitle>
            <b>2. Copyright</b>
          </Subtitle>
          <Paragraph>
            Continutul si structura site-ului www.nenos-academy.ro sunt
            proprietatea Nenos Software SRL, si sunt protejate de Legea nr.
            8/1996 privind dreptul de autor si drepturile conexe, Legea nr.
            83/2014 privind inventiile de servicii, Legea nr. 64/1991 privind
            brevetele de inventie, Legea nr. 11/1991 privind combaterea
            concurentei ilegale, precum si orice alte prevederi legale in
            vigoare (reglementari privind lucrarile industriale, legislația
            aplicabilă mărcilor distinctive etc.).
          </Paragraph>
          <Paragraph>
            Orice utilizare neautorizată a conținutului acestui site poate
            încălca legile enumerate mai sus, precum și orice alte prevederi
            legale. Orice drepturi care nu sunt acordate sunt rezervate așa cum
            apar în conținutul site-ului web.
          </Paragraph>
          <Paragraph>
            Orice reproducere, transfer, partajare sau stocare a unor părți ale
            site-ului web sau a întregului său conținut, indiferent de
            mijloacele utilizate, fără acordul prealabil scris al deținătorului
            site-ului www.nenos-academy.ro, este interzisă. Acest lucru nu se
            aplică distribuirilor pe rețelele sociale și partajărilor numai în
            scopuri publicitare.
          </Paragraph>

          <Subtitle>
            <b>
              Denumirea/marca comercială și conținutul site-ului web
              www.nenos-academy.ro
            </b>
          </Subtitle>
          <Paragraph>
            Toate denumirile comerciale, mărcile comerciale, logo-urile și
            imaginile afișate pe acest site sunt proprietatea Nenos Software.
            Accesul la acest site nu conferă dreptul de utilizare a conținutului
            său fără acordul prealabil scris.
          </Paragraph>

          <Subtitle>
            <b>
              4. Garanția și clauza de siguranță pentru utilizarea site-ului
              www.nenos-academy.ro
            </b>
          </Subtitle>
          <Paragraph>
            Nenos Software nu garanteaza ca accesul la site-ul
            www.nenos-academy.ro si continutul acestuia nu va fi lipsit de
            intreruperi sau erori. Site-ul web și conținutul său sunt oferite cu
            titlu informativ și fără garanții, inclusiv, dar fără a se limita la
            disponibilitatea, acuratețea, securitatea și corectitudinea
            informațiilor. În plus, site-ul web poate conține erori sau alte
            greșeli, inexactități sau erori de tastare datorate modificărilor de
            conținut, aspect și servicii.
          </Paragraph>
          <Paragraph>
            Informațiile de pe site pot fi depășite, iar Nenos Software nu își
            asumă niciun angajament de a le actualiza.
          </Paragraph>
          <Paragraph>
            În cazul în care orice material sub drepturi de autor este publicat
            pe site-ul www.nenos-academy.ro sau o astfel de publicare are loc cu
            încălcarea legii, vă rugăm să ne anunțați și îl vom elimina imediat.
          </Paragraph>
          <Paragraph>
            Nenos Software nu își asumă nicio răspundere pentru viruși sau
            defecțiuni care ar putea afecta echipamentele IT (computer,
            notebook-uri, tablete, telefoane etc.) sau orice alte active care
            facilitează accesul site-ului web www.nenos-academy.ro în urma unui
            astfel de acces.
          </Paragraph>

          <Subtitle>
            <b>5. Legea aplicabilă</b>
          </Subtitle>
          <Paragraph>
            Nenos Software controlează acest site de pe teritoriul României.
            Astfel, toate informațiile disponibile pe site-ul
            www.nenos-academy.ro sunt considerate a fi furnizate în România, iar
            utilizatorii acestora urmează să se supună reglementărilor legale
            ale statului român.
          </Paragraph>

          <Subtitle>
            <b>6. Diverse</b>
          </Subtitle>
          <Paragraph>
            În cazul în care orice prevedere a acestui aviz juridic devine sau
            este declarată nulă sau inaplicabilă, acest lucru nu va afecta
            valabilitatea și aplicabilitatea celorlalte prevederi ale acestuia.
          </Paragraph>
          <Paragraph>
            Acest aviz juridic stabilește ACORDUL dintre dvs. și Nenos Software,
            în calitate de operator al site-ului www.nenos-academy.ro, în ceea
            ce privește acceptarea termenilor și condițiilor.
          </Paragraph>
          <Paragraph>
            În cazul în care o persoană nu este de acord cu termenii și
            condițiile stabilite la punctele 2-5 de mai sus, aceasta are
            obligația de a întrerupe orice utilizare a site-ului.
          </Paragraph>
          <Paragraph>
            Politica de confidențialitate și informații privind siguranța
            datelor clienților noștri pot fi găsite la pagina:{' '}
            <b>Politica de confidențialitate</b>.
          </Paragraph>

          <Title>Contact:</Title>
          <Paragraph>Numele companiei: NENOS SOFTWARE SRL</Paragraph>
          <Paragraph>
            Adresă: Bld. Marea Unire Nr. 15, Bl. U4, Spatiu Comercial 2, Galati
          </Paragraph>
          <Paragraph>Telefon: 0336 401 269 </Paragraph>
          <Paragraph>E-mail: contact@nenos-academy.ro</Paragraph>
        </Box>
      </SectionContainerCore>
    </div>
  );
};

export default TermsAndConditions;
