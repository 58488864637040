import React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import BodyContent from './app-structure/body-content';
import { SectionsProvider } from './resources/SectionsContext';
import { theme } from './resources/theming';
import FooterContent from './app-structure/footer';
import './App.css';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl" height="100%">
        <SectionsProvider>
          <BodyContent />

          <FooterContent />
        </SectionsProvider>
      </Box>
    </ChakraProvider>
  );
}

export default App;
