import React, { useEffect, useMemo, useState } from 'react';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { motion } from 'framer-motion';

import {
  Flex,
  Text,
  Box,
  Grid,
  Button,
  Container,
  chakra,
  shouldForwardProp,
  Fade,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import pythonLogo from '../resources/images/pythonLogo.svg';
import fullStackLogo from '../resources/images/fullStackLogo.svg';
import machineLearningLogo from '../resources/images/machineLearningLogo.svg';
import devOpsLogo from '../resources/images/devOpsLogo.svg';
import { COLORS } from '../resources/colors';
import { HEADERS } from '../resources/constants';
import SectionContainer from './core/SectionContainer';
import CoreTitle from './core/CoreTitle';
import CoreParagraph from './core/CoreParagraph';

const PROGRAMS = [
  'Full Stack Web Developer',
  'Python Developer',
  'Machine Learning Developer',
  'DevOps Engineer',
];

const PROGRAM_LOGO = [
  fullStackLogo,
  pythonLogo,
  machineLearningLogo,
  devOpsLogo,
];

const PROGRAM_DESCRIPTION = [
  `Acest curs de dezvoltare web FullStack este conceput pentru a echipa participanții cu
 o serie de cunoștințe integrate, de front-end și back-end. Programul acoperă
 tehnologii esențiale, punând un accent deosebit pe utilizarea bazelor de date și pe
 arhitectura aplicațiilor web. Cursul încurajează aplicarea practică a teoriilor în proiecte
 reale, asigurând o înțelegere completă a dezvoltării web moderne.`,

  `Cursul de Python developer oferă o introducere amănunțită în unul dintre cele mai
 populare și versatilite limbaje de programare. Participanții vor învăța să utilizeze
 Python pentru dezvoltarea de aplicații, automatizarea proceselor și analiza datelor.
 Acest curs pune accent pe practică, de la scrierea scripturilor simple la implementarea
 de soluții si proiecte complexe.`,

  `Acest curs de Machine Learning își propune să inițieze participanții în principiile
 fundamentale ale învățării supervizate și nesupervizate. Se concentrează pe cultivarea
 competențelor esențiale în statistică, teoria probabilităților și metodele analitice
 necesare pentru procesarea și interpretarea seturilor de date complexe.`,

  `Acest curs este conceput pentru a vă echipa cu competențe avansate în utilizarea
 ecosistemului Azure, permițându-vă să implementați, să monitorizați și să optimizați
 soluții cloud complexe. Veți dobândi o înțelegere profundă a automatizării proceselor
 și a managementului infrastructurii, esențiale pentru îmbunătățirea colaborării
 interdepartamentale și pentru accelerarea ciclurilor de livrare a software-ului.`,
];

const PROGRAM_MARKS = [
  [
    'HTML, CSS',
    'React JS',
    'Node JS',
    'Express JS',
    'Apollo Graphql',
    'MySQL',
    'GIT',
    'VS Code',
  ],
  ['SQLAlchemy', 'Fast API', 'Poetry', 'Pycharm', 'VS Code', 'GIT', 'Docker'],
  [
    'Tensorflow',
    'Keras',
    'Scikit-learn',
    'KubeFlow',
    'ML Flow',
    'Pycharm',
    'Jupyter notebooks',
    'Dash',
    'GCloud Platform',
  ],
  ['Azure portal', 'Azure Devops', 'Terraform', 'Docker', 'Kubernetes'],
];

const TrainingPrograms = () => {
  const [selectedProgramIndex, setSelectedProgramIndex] = useState(null);
  const [triggerClick, setTriggerClick] = useState(false);

  const handleSelectProgram = index => {
    setSelectedProgramIndex(index);
  };

  useEffect(() => {
    const buttons = document.querySelectorAll('button');
    for (const trigger of buttons) {
      trigger.addEventListener('click', e => {
        setTriggerClick(true);
      });
    }

    setTriggerClick(false);
  }, []);

  const ChakraBox = chakra(motion.div, {
    shouldForwardProp: prop => shouldForwardProp(prop) || triggerClick,
  });

  const carouselItems = useMemo(
    () =>
      PROGRAMS.map((program, index) => (
        <Flex
          key={index}
          className={
            selectedProgramIndex === null ? 'simpleCard' : 'programCard'
          }
          direction="row"
          justifyContent="center"
          onClick={() => handleSelectProgram(index)}
          _hover={{
            cursor: 'pointer',
          }}
          gap={10}
        >
          <Flex
            width={selectedProgramIndex === null ? '240px' : '180px'}
            height={selectedProgramIndex === null ? '240px' : '180px'}
            flexDirection="column"
            bg={COLORS.cardColor}
            gap={2}
            justify="center"
            align="center"
            borderRadius="30px"
            py={8}
            px={2}
          >
            <img width="60" src={PROGRAM_LOGO[index]} alt="bg" />
            <Text
              fontSize={{
                base: '17px',
                sm: '17px',
                md: '17px',
                lg: '17px',
                xl: '17px',
                '2xl': '17px',
              }}
              fontWeight="bold"
            >
              {program}
            </Text>
          </Flex>
        </Flex>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProgramIndex]
  );

  return (
    <SectionContainer fullWidth={true} sectionName={HEADERS.trainingPrograms}>
      <Box
        className="section"
        display="flex"
        flexDirection="column"
        gap={10}
        minH="700px"
      >
        <CoreTitle text="PROGRAME DE TRAINING" mb="10" />

        {selectedProgramIndex === null ? (
          <Flex
            width="100%"
            maxWidth={{
              base: '100%',
              sm: '100%',
              md: '100%',
              lg: '90%',
              xl: '90%',
              '2xl': '90%',
            }}
            justifyContent="center"
            gap={20}
            alignSelf="center"
          >
            <Grid
              templateColumns={{
                base: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(2, 1fr)',
                xl: 'repeat(4, 1fr)',
                '2xl': 'repeat(4, 1fr)',
              }}
              gap={6}
            >
              {carouselItems}
            </Grid>
          </Flex>
        ) : (
          <Fade in={selectedProgramIndex !== null}>
            <Flex
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              flexDirection={{
                base: 'column',
                sm: 'column',
                md: 'column',
                lg: 'column',
                xl: 'column',
                '2xl': 'row',
              }}
              gap={10}
            >
              <Container
                display="flex"
                alignItems="center"
                justifyContent="center"
                margin="0"
                padding="0"
                minHeight={{
                  base: '700px',
                  sm: '550px',
                  md: '400px',
                  lg: '400px',
                }}
              >
                <ChakraBox
                  animate={{
                    scale: [0.4, 1],
                  }}
                  transition={{
                    duration: 0.3,
                    ease: 'easeInOut',
                  }}
                >
                  <Flex
                    maxW="700px"
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    flex="1"
                    padding="0"
                    direction="column"
                    gap={7}
                  >
                    <Text
                      fontSize={{
                        base: '30px',
                        sm: '25px',
                        md: '36px',
                        lg: '25px',
                        xl: '30px',
                        '2xl': '36px',
                      }}
                      fontWeight="bold"
                    >
                      {PROGRAMS[selectedProgramIndex]}
                    </Text>

                    <CoreParagraph
                      text={PROGRAM_DESCRIPTION[selectedProgramIndex]}
                    />

                    {selectedProgramIndex === 2 && (
                      <CoreParagraph
                        text="Acest curs se realizează la cerere pentru organizații.
                  Pentru participare sunt necesare cunoștințe avansate de
                  python."
                        fontWeight="bold"
                      />
                    )}

                    <Flex flexWrap="wrap" width="100%" justifyContent="center">
                      {PROGRAM_MARKS[selectedProgramIndex].map(mark => (
                        <Text
                          key={mark}
                          mt="2"
                          mb="2"
                          ml="5"
                          mr="5"
                          fontWeight="bold"
                        >
                          {mark}
                        </Text>
                      ))}
                    </Flex>
                  </Flex>
                </ChakraBox>
              </Container>

              <div className="programsCarousel" gap={6}>
                <AliceCarousel
                  infinite
                  dotsDisabled={true}
                  disableDotsControls
                  buttonsDisabled={true}
                  items={carouselItems}
                  animationDuration={500}
                  paddingLeft="0"
                  paddingRight="0"
                  activeIndex={selectedProgramIndex}
                  onSlideChanged={e => setSelectedProgramIndex(e.item)}
                  renderPrevButton={() => (
                    <Button bg={COLORS.green}>
                      <ChevronLeftIcon />
                    </Button>
                  )}
                  renderNextButton={() => (
                    <Button bg={COLORS.green}>
                      <ChevronRightIcon />
                    </Button>
                  )}
                />
              </div>
            </Flex>
          </Fade>
        )}
      </Box>
    </SectionContainer>
  );
};

export default TrainingPrograms;
