import React from 'react';
import { Text } from '@chakra-ui/react';

const CoreParagraph = ({ text, ...styleProps }) => {
  return (
    <Text align="justify" fontSize="16px" {...(styleProps || {})}>
      {text}
    </Text>
  );
};

export default CoreParagraph;
