import React from 'react';
import { Box, Flex, Grid } from '@chakra-ui/react';

import { HEADERS } from '../resources/constants';
import SectionContainer from './core/SectionContainer';
import CoreTitle from './core/CoreTitle';
import CoreParagraph from './core/CoreParagraph';
import CoreSubtitle from './core/CoreSubtitle';
import { COLORS } from '../resources/colors';

const data = [
  {
    subtitle: 'Excelență în Educație',
    description: `Academiile oferă o experiență
 educațională de excepție, bazată pe un
 curriculum solid și actualizat în
 permanență.`,
  },
  {
    subtitle: ' Fundament Teoretic Detaliat',
    description: ` Fiecare modul al Academiilor
 beneficiază de o bază teoretică solidă,
 cu materiale de studiu detaliate și
 asistență din partea mentorilor.`,
  },
  {
    subtitle: 'Abordare Practică și Interactivă',
    description: `Prin exerciții practice și proiecte te vei
 dezvolta într-un mod interactiv și
 practic, primind feedback constant.`,
  },
  {
    subtitle: 'Oportunități de Dezvoltare',
    description: `Academia îți oferă posibilitatea de a te
 conecta cu profesioniști cu interese
 similare și te sprijină în conturarea
 CV-ului.`,
  },
  {
    subtitle: 'Evaluare Centrată pe Progres',
    description: `Fiecare modul include evaluări orientate
 către progres, care te ajută să te auto
 evaluezi și să îți consolidezi
 cunoștințele.`,
  },
  {
    subtitle: 'Flexibilitate și Accesibilitate',
    description: `Cursurile sunt concepute pentru a fi
 flexibile și accesibile, cu posibilitatea de
 a participa online de oriunde te-ai afla,
 cu sesiuni programate seara.`,
  },
];

const Benefits = () => {
  return (
    <SectionContainer sectionName={HEADERS.benefits} fullWidth>
      <Box className="section" minH="1000px">
        <Flex
          direction="column"
          maxWidth={{
            base: '100%',
            sm: '100%',
            md: '100%',
            lg: '90%',
            xl: '90%',
            '2xl': '90%',
          }}
          alignSelf="center"
          gap={20}
        >
          <CoreTitle text="AVANTAJELE PROGRAMULUI" />

          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(3, 1fr)',
              xl: 'repeat(3, 1fr)',
              '2xl': 'repeat(3, 1fr)',
            }}
            gap={12}
          >
            {data.map((item, index) => (
              <Flex
                key={index}
                width="100%"
                alignItems="center"
                justifyContent="center"
                flex="1"
                padding="6"
                direction="column"
                gap={6}
                bg={COLORS.cardColor}
                borderRadius="20px"
              >
                <CoreSubtitle text={item.subtitle} />

                <CoreParagraph text={item.description} align="center" />
              </Flex>
            ))}
          </Grid>
        </Flex>
      </Box>
    </SectionContainer>
  );
};

export default Benefits;
