const googleReviews = [
  {
    name: 'Andronache Razvan',
    reviewChunks: [
      'A very pleasant and productive experience, well organized, interactive courses, properly organized and supported. Very understanding and patient mentors.',
      'Special thanks to our Front-End mentor, Cezar',
    ],
  },
  {
    name: 'Razvan Anghel',
    reviewChunks: [
      'Collaboration with Nenos Academy is exceptional.',
      'I write this review as a person who started the courses with 0 knowledge.',
      'Patience, kindness, course materials, presentation are far beyond expectations.',
      'Nenos Academy is an extremely serious, helpful team and any interaction with them is a great pleasure!',
      'I recommend them with all my strength and confidence!',
    ],
  },
  {
    name: 'Alina Craciun',
    reviewChunks: [
      'I followed the Python developer course and was very satisfied. It is an advanced, practical course, with new technologies and bookstores, and Alex was very serious, dedicated, professional and supported us with explanations, practical examples and help in the errors encountered, plus stories from his own experience. I have done other courses before, but this one seemed to me to be the most complete in all aspects, and it was also the most challenging.',
      'I liked that I manage to organize myself and get out of my comfort zone and learn something new, in a professional work environment.',
    ],
  },
  {
    name: 'Spiru Paraschiv',
    reviewChunks: [
      'Course - Python',
      '"A well-structured course for learning, with a great instructor like Alex who made learning a pleasant task. I think the experience was very positive and I also thank the organizers for this opportunity to participate in these free courses."',
    ],
  },
  {
    name: 'Ionatan Chirita',
    reviewChunks: [
      '‘Nenos Academy is the perfect place if you want to learn programming from scratch or add knowledge on top of what you already have, as it was in my case. Here I benefited from mentors willing to share knowledge in a pleasant way, but also from a lot of beneficial materials that helped me become better.',
      'I recommend with confidence!’',
    ],
  },
  {
    name: 'George Baroana',
    reviewChunks: [
      '‘A good experience, well-trained people, and a pleasant environment.’',
    ],
  },
  {
    name: 'Mihai Niculcea',
    reviewChunks: [
      `‘I attended the academy's courses and can say that I improved a lot when I was in the academy vs. how much time I did in my free time. I have the information very well structured and easy to understand, and all the people I collaborated with were happy to answer my questions and were patient until I understood. I was getting feedback on "assignments" constantly. Something that helps you improve your skills.`,
      'The "homework" consisted of things we discussed in a meeting and could apply immediately afterward.’',
    ],
  },
  {
    name: 'Mihaita Alexandru',
    reviewChunks: [
      `‘I signed up at Nenos Academy and I can say that the trainers are very well prepared, they teach well-structured quality courses. The lessons are interactive and can be learned very easily. I recommend it!’`,
    ],
  },
  {
    name: 'Alexandra Varvarichi',
    reviewChunks: [
      'I appreciate the fact that you follow us and always make sure that everything is fine and that we don’t miss anything. Thank you!',
      `The information accumulated so far really helped me (especially the ones from HTML&CSS) and for sure, when I will be a little bit more available, I'll go over the feedback given by Cezar in the 2 exams and I'll try to deepen it.`,
      'Thank you very much for your support, format and openness!',
    ],
  },
  {
    name: 'Huleva Bogdan',
    reviewChunks: [
      'My experience so far has been very pleasant in all respects, with professionalism and seriousness from all participants and I look forward to continuing this journey together if the exam grade allows me of course.',
    ],
  },
];

export default googleReviews;
