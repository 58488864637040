import React from 'react';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import SectionContainer from './core/SectionContainer';
import CoreTitle from './core/CoreTitle';
import CoreParagraph from './core/CoreParagraph';
import CoreSubtitle from './core/CoreSubtitle';
import { COLORS } from '../resources/colors';
import { HEADERS } from '../resources/constants';

const data = [
  {
    subtitle: 'Înscriere',
    description: 'Înscrie-te la cursul dorit pe site-ul nostru.',
  },
  {
    subtitle: 'Discuție Preliminară',
    description: `După alegerea cursului dorit, vei fi invitat să
 participi la o discuție cu unul dintre colegii
 noștri. Vei afla mai multe detalii despre curs,
 vei putea pune orice întrebare și vei stabili
 momentul testării inițiale.`,
  },
  {
    subtitle: 'Susținerea Testului Inițial',
    description: `Testul logic include 5 întrebări: 3 de IQ, una
 de logică situațională și un algoritm. Pentru a
 continua, trebuie să obții o notă minimă de 5
 din 10. Dacă nu reușești, ai posibilitatea de a
 încerca din nou.
`,
  },
  {
    subtitle: 'Sesiuni Live cu Mentorul',
    description: `Sesiunile de instruire se desfășoară de două ori pe săptămână, seara, cu o durată de aproximativ 2-3 ore fiecare.`,
  },
  {
    subtitle: 'Evaluare și Progres',
    description: `Pentru a avansa la următorul modul, vei
 susține un examen, prin care îți vei monitoriza
 și progresul. În cazul în care nu treci
 examenul, ai posibilitatea de a-l repeta`,
  },
  {
    subtitle: 'Feedback și Absolvire',
    description: `Pe parcursul academiei, vei completa chestionare de feedback pentru a ne ajuta să
 îmbunătățim cursurile. Absolvirea cursului se
 realizează după predarea și prezentarea
 proiectului final, realizat în ultimul modul.`,
  },
];

const RecruitmentCircuit = () => {
  return (
    <SectionContainer sectionName={HEADERS.recruitmentCircuit} fullWidth>
      <Box className="section">
        <Flex
          direction="column"
          maxWidth={{
            base: '100%',
            sm: '100%',
            md: '100%',
            lg: '90%',
            xl: '90%',
            '2xl': '90%',
          }}
          alignSelf="center"
          gap={10}
        >
          <CoreTitle text="ETAPE DE ÎNSCRIERE" />
          <CoreParagraph
            fontSize="18px"
            maxW="1200px"
            textAlign="center"
            margin="auto"
            text="Suntem alături de tine la fiecare pas pe drumul tău. Ne vom dedica să te îndrumăm și să îți furnizăm resursele necesare, dar efortul și determinarea de a-ți eleva pasiunea la un nivel superior îți aparțin în întregime. "
          />

          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(3, 1fr)',
              xl: 'repeat(3, 1fr)',
              '2xl': 'repeat(3, 1fr)',
            }}
            gap={8}
          >
            {data.map((item, index) => (
              <Flex
                key={index}
                width="100%"
                alignItems="center"
                justifyContent="center"
                flex="1"
                padding="6"
                direction="column"
                gap={6}
                bg={COLORS.cardColor}
                borderRadius="20px"
              >
                <Flex justifyContent="center" alignItems="center" gap={6}>
                  <Flex
                    bg="white"
                    borderRadius="50px"
                    width="45px"
                    minW="45px"
                    justifyContent="center"
                  >
                    <Text
                      color={COLORS.darkBlue}
                      fontSize="28px"
                      fontWeight="bold"
                    >
                      {index + 1}
                    </Text>
                  </Flex>
                  <CoreSubtitle text={item.subtitle} />
                </Flex>

                <CoreParagraph text={item.description} align="center" />
              </Flex>
            ))}
          </Grid>
        </Flex>
      </Box>
    </SectionContainer>
  );
};

export default RecruitmentCircuit;
