import React from 'react';
import { Text } from '@chakra-ui/react';

const CoreSubtitle = ({ text, ...styleProps }) => {
  return (
    <Text
      fontSize={{
        base: '16px',
        sm: '20px',
        md: '22px',
        lg: '24px',
        xl: '28px',
        '2xl': '30px',
      }}
      fontWeight="bold"
      {...(styleProps || {})}
    >
      {text}
    </Text>
  );
};

export default CoreSubtitle;
