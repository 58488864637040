import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

const NotFound = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      position="relative"
      minHeight="calc(100vh - 350px)"
    >
      <Text fontSize={36}>Page not found</Text>
    </Flex>
  );
};

export default NotFound;
