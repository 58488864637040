import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { HEADERS } from '../resources/constants';
import SectionContainer from './core/SectionContainer';
import CoreTitle from './core/CoreTitle';
import { COLORS } from '../resources/colors';

const InfoBox = ({ message }) => {
  return (
    <Box
      bg={COLORS.cardColor}
      cursor="default"
      borderRadius="10px"
      padding="17px 30px"
    >
      <Text
        fontSize={{
          base: '23px',
          sm: '23px',
          md: '25px',
          lg: '30px',
        }}
        color="white"
        fontWeight="bold"
        textAlign="center"
      >
        {message}
      </Text>
    </Box>
  );
};

const AboutUs = () => {
  return (
    <SectionContainer sectionName={HEADERS.aboutUs} fullWidth>
      <Box className="section" minH="700px">
        <Flex
          flexDir="column"
          justify="space-evenly"
          h="100%"
          alignSelf="center"
          maxWidth={{
            base: '95%',
            sm: '95%',
            md: '95%',
            lg: '90%',
            xl: '90%',
            '2xl': '90%',
          }}
          gap={14}
        >
          <CoreTitle text="DESPRE NOI" />

          <Text fontSize="18px" align="justify">
            Nenos Academy își propune să fie prima experiență, atât pentru
            studenți, cât și pentru orice persoană interesată, într-un mediu de
            muncă real. Misiunea noastră este de a dezvolta o generație de
            talente, capabile să facă față industriei digitale în creștere din
            România.
            <b>
              {' '}
              Cunoașterea este cea mai înțeleaptă investiție pe care o persoană
              o poate face.
            </b>{' '}
            De aceea, suntem dedicați să împărtășim expertiza noastră cu alții.
          </Text>

          <Text fontSize="18px" align="justify">
            Începând cu anul 2024,
            <b>
              {' '}
              Nenos Software sponsorizează programul de academie prin
              subvenționarea a 20 de burse
            </b>{' '}
            pentru cele 3 programe de învățare, acordate în funcție de
            performanța la testul de logică și a unei scrisori de motivație.
            <b>
              {' '}
              Cei care obțin cele mai înalte scoruri vor fi eligibili pentru
              cursuri gratuite, în timp ce restul cursanților vor avea opțiunea
              de a parcurge academia contra cost.
            </b>{' '}
            Pentru mai multe informații despre costuri dar și despre programul
            nostru de burse, vă rugăm să nu ezitați să ne contactați!
          </Text>

          <Flex
            flexDir={{
              base: 'column',
              sm: 'row',
              md: 'row',
              lg: 'row',
              xl: 'row',
              '2xl': 'row',
            }}
            gap={12}
            justify="center"
            alignItems="center"
          >
            <InfoBox message="20 burse" />
            <InfoBox message="60 locuri cu taxă" />
          </Flex>
        </Flex>
      </Box>
    </SectionContainer>
  );
};

export default AboutUs;
