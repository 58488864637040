import React, { useState, useRef } from 'react';
import {
  Input,
  Textarea,
  Text,
  Flex,
  Button,
  useToast,
  Select,
  Box,
} from '@chakra-ui/react';
import ReCAPTCHA from 'react-google-recaptcha';

import { HEADERS } from '../resources/constants';
import { COLORS } from '../resources/colors';
import CREDENTIALS from '../resources/app-data/keys';
import SectionContainer from './core/SectionContainer';
import CoreTitle from './core/CoreTitle';
import ApiHelper from '../helpers/api-helper';
import { useNotification } from '../hooks/notification';

const INPUT_STYLE = {
  bg: 'rgb(48, 102, 134, 0.6)',
  size: 'lg',
  textColor: 'white',
  width: '100%',
  fontSize: '20px',
};

const FIELD_CONTAINER_STYLE = {
  direction: 'column',
  alignItems: 'flex-start',
  gap: 2,
};

const INITIAL_FORM = {
  fullName: '',
  email: '',
  phone: '',
  file: null,
  message: '',
  trainingProgram: '',
};

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const JoinUs = () => {
  const recaptchaRef = useRef();
  const notification = useNotification(useToast());
  const [form, setForm] = useState(INITIAL_FORM);
  const [recaptchaToken, setToken] = useState(null);
  const [operationInProgress, setOperationInProgress] = useState(false);

  const onFormUpdate = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onUploadClick = () => {
    try {
      document.getElementById('fileUploadInput').click();
    } catch {}
  };

  const onFileUpload = e => {
    try {
      const currentFile = e.target.files[0];
      if (currentFile.type === 'application/pdf') {
        setForm({ ...form, file: e.target.files[0] });
        return;
      }

      notification.error('Invalid file extension!', 'Please upload PDF only!');
    } catch {
      notification.error('Something went wrong!', 'Please try again!');
    }
  };

  const onSubmitForm = () => {
    setOperationInProgress(true);

    ApiHelper.joinUsSubmit(form)
      .then(() => {
        notification.success(
          'Thank you for submitting!',
          'We will contact you!'
        );
        setOperationInProgress(false);
        setForm(INITIAL_FORM);
        recaptchaRef?.current?.reset();
        document.getElementById('fileUploadInput').value = '';
      })
      .catch(err => {
        notification.error('Something went wrong!', 'Please try again!');
        recaptchaRef?.current?.reset();
        setOperationInProgress(false);
      });
  };

  const isEmailInvalid =
    form.email?.trim() !== '' && !EMAIL_REGEX.test(form.email?.trim());
  const isPhoneInvalid =
    form.phone && (!form.phone?.startsWith('07') || form.phone?.length !== 10);

  const isFormValid =
    form.fullName?.trim() !== '' &&
    form.email?.trim() !== '' &&
    form.phone &&
    form.trainingProgram &&
    form.file &&
    !isPhoneInvalid &&
    !isEmailInvalid;

  return (
    <SectionContainer fullWidth sectionName={HEADERS.joinUs}>
      <Box className="section" display="flex" flexDirection="column" gap={20}>
        <CoreTitle text=" ALĂTURĂ-TE ACADEMIEI NENOS!" />

        <Flex
          direction="column"
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <Flex
            width="100%"
            maxW={{
              base: '100%',
              md: '100%',
              lg: '1100px',
            }}
            margin="auto"
            direction={{
              base: 'column',
              sm: 'column',
              md: 'column',
              lg: 'row',
              xl: 'row',
              '2xl': 'row',
            }}
            alignItems="flex-start"
            justifyContent="flex-end"
            gap={10}
          >
            <Flex direction="column" gap={2} w="100%">
              <Flex {...FIELD_CONTAINER_STYLE} mt="0">
                <Text fontSize="20px">Nume și Prenume*</Text>
                <Input
                  fontSize="20px"
                  name="fullName"
                  placeholder="John Smith"
                  isDisabled={operationInProgress}
                  value={form.fullName}
                  onChange={onFormUpdate}
                  {...INPUT_STYLE}
                />
              </Flex>

              <Flex {...FIELD_CONTAINER_STYLE}>
                <Text fontSize="20px"> Adresa de email*</Text>
                <Input
                  isInvalid={isEmailInvalid}
                  name="email"
                  placeholder="johnSmith@gmail.com"
                  isDisabled={operationInProgress}
                  value={form.email}
                  onChange={onFormUpdate}
                  _focus={{
                    borderColor: isEmailInvalid ? 'error.600' : 'inherit',
                  }}
                  {...INPUT_STYLE}
                />
                {isEmailInvalid && (
                  <Text fontSize="20px" color="red.600" fontStyle="italic">
                    Te rog, introdu o adresă de email validă!
                  </Text>
                )}
              </Flex>

              <Flex {...FIELD_CONTAINER_STYLE}>
                <Text fontSize="20px"> Sunt interesat de cursul*</Text>
                <Select
                  name="trainingProgram"
                  placeholder="Selectează cursul"
                  {...INPUT_STYLE}
                  isDisabled={operationInProgress}
                  value={form.trainingProgram}
                  onChange={onFormUpdate}
                >
                  <option value="fullstack">Fullstack Developer</option>
                  <option value="python">Python Developer</option>
                  <option value="ml">Machine Learning Developer</option>
                  <option value="devops">DevOps</option>
                </Select>
              </Flex>

              <Flex {...FIELD_CONTAINER_STYLE}>
                <Text fontSize="20px">Număr de telefon*</Text>
                <Input
                  isInvalid={isPhoneInvalid}
                  name="phone"
                  placeholder="07********"
                  type="number"
                  {...INPUT_STYLE}
                  isDisabled={operationInProgress}
                  value={form.phone}
                  onChange={onFormUpdate}
                  _focus={{
                    borderColor: isEmailInvalid ? 'error.600' : 'inherit',
                  }}
                />
                {isPhoneInvalid && (
                  <Text fontSize="20px" color="red.600" fontStyle="italic">
                    Te rog, introdu un număr de telefon valid!
                  </Text>
                )}
              </Flex>
            </Flex>

            <Flex direction="column" gap={2} w="100%">
              <Flex {...FIELD_CONTAINER_STYLE}>
                <Text fontSize="20px">CV*</Text>
                <Flex width="100%">
                  <input
                    id="fileUploadInput"
                    style={{
                      visibility: 'hidden',
                      width: '0px',
                      height: '0px',
                    }}
                    type="file"
                    accept="application/pdf"
                    onChange={onFileUpload}
                  />
                  <Input
                    name="file"
                    placeholder="Fișier"
                    mr="4"
                    {...INPUT_STYLE}
                    isReadOnly
                    isDisabled={operationInProgress}
                    value={form.file?.name || ''}
                  />
                  <Button
                    fontSize="20px"
                    minWidth="130px"
                    width="100%"
                    flex="1"
                    ml="2"
                    height="inherit"
                    isDisabled={operationInProgress}
                    onClick={() => onUploadClick('fileUploadInput')}
                    bg={COLORS.green}
                  >
                    Încarcă
                  </Button>
                </Flex>
              </Flex>

              <Flex {...FIELD_CONTAINER_STYLE} mb="4">
                <Text fontSize="20px">Mesaj</Text>
                <Textarea
                  name="message"
                  placeholder=" Lasă-ne un mesaj aici!"
                  minHeight="140px"
                  isDisabled={operationInProgress}
                  value={form.message}
                  onChange={onFormUpdate}
                  {...INPUT_STYLE}
                />
              </Flex>
            </Flex>
          </Flex>

          <Flex justifyContent="center" w="100%">
            {/* TODO: Check in prod dims */}
            <Box minWidth="200px" width="100%" maxW={'305px'} overflow="auto">
              <ReCAPTCHA
                hl="en"
                sitekey={CREDENTIALS.recaptcha}
                onChange={token => setToken(token)}
                ref={recaptchaRef}
                style={{ width: '200px' }}
              />
            </Box>
          </Flex>

          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            maxW={{
              base: '100%',
              md: '100%',
              lg: '1100px',
            }}
            margin="auto"
          >
            <Text fontSize="20px" textAlign="center" mt="4">
              Prin trimiterea acestui formular, confirmați că ați citit și
              sunteți de acord cu Politica de Confidențialitate și Termenii și
              Condițiile, inclusiv utilizarea numelui, adresei de email și altor
              detalii pe Nenos Academy.
            </Text>

            <Button
              fontSize="24px"
              bg={COLORS.green}
              mt="6"
              isDisabled={
                operationInProgress || !isFormValid || !recaptchaToken
              }
              isLoading={operationInProgress}
              onClick={onSubmitForm}
              _disabled={{
                opacity: 0.7,
                cursor: 'not-allowed',
              }}
              borderRadius="20px"
              h="70px"
              w="220px"
            >
              APLICĂ ACUM
            </Button>
          </Flex>
        </Flex>
      </Box>
    </SectionContainer>
  );
};

export default JoinUs;
