import React, { useMemo } from 'react';

import { Button, Flex, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import googleReviews from '../resources/app-data/google-reviews';
import { GoogleLogoSvg } from '../resources/images/GoogleLogoSvg';
import { ReviewStartSvg } from '../resources/images/ReviewStarSvg';
import { COLORS } from '../resources/colors';

const RESPONSIVE = {
  0: {
    items: 1,
  },
};

export const GoogleReviews = () => {
  const items = useMemo(
    () =>
      googleReviews.map((review, index) => (
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          direction="column"
          key={index}
        >
          <Text
            fontWeight="bold"
            mb="3"
            fontSize={{
              base: '18px',
              sm: '20px',
              md: '25px',
              lg: '28px',
              xl: '34px',
              '2xl': '34px',
            }}
          >
            {review.name}
          </Text>

          <Flex mb="5">
            <ReviewStartSvg />
            <ReviewStartSvg />
            <ReviewStartSvg />
            <ReviewStartSvg />
            <ReviewStartSvg />
          </Flex>

          <Flex direction="column">
            {review.reviewChunks?.map((reviewChunk, index) => (
              <Text
                maxW="800px"
                key={index}
                fontSize="16px"
                mb="16px"
                fontStyle="italic"
                textAlign="center"
              >
                {reviewChunk}
              </Text>
            ))}
          </Flex>

          <GoogleLogoSvg style={{ marginTop: '25px' }} />
        </Flex>
      )),
    []
  );

  return (
    <Flex
      w="100%"
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <AliceCarousel
        autoPlay
        infinite
        mouseTracking
        items={items}
        responsive={RESPONSIVE}
        animationDuration={1500}
        autoPlayInterval={1500}
        renderPrevButton={() => (
          <Button bg={COLORS.green}>
            <ChevronLeftIcon />
          </Button>
        )}
        renderNextButton={() => (
          <Button bg={COLORS.green}>
            <ChevronRightIcon />
          </Button>
        )}
      />
    </Flex>
  );
};
