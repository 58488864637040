import React from 'react';
import { Container } from '@chakra-ui/react';

// NOTE: Style here the container based on screen size
const SectionContainerCore = ({ children, fullWidth, refObj, ...props }) => {
  const mediaQueryStyleProps = fullWidth
    ? { maxWidth: '100%' }
    : {
        maxWidth: {
          base: '85%',
          sm: '85%',
          md: '85%',
          lg: '75%',
          xl: '70%',
          '2xl': '70%',
        },
      };

  return (
    <Container
      ref={refObj}
      bg="transparent"
      pt="36"
      pb="36"
      {...mediaQueryStyleProps}
      {...(props || {})}
    >
      {children}
    </Container>
  );
};

export default SectionContainerCore;
