import React, { useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';

import {
  Flex,
  Text,
  Box,
  Grid,
  Button,
  Container,
  chakra,
  shouldForwardProp,
  Fade,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import { HEADERS } from '../resources/constants';
import Mentor1 from '../resources/images/mentor1.png';
import Mentor2 from '../resources/images/mentor2.png';

import { GoogleReviews } from './google-reviews';
import SectionContainer from './core/SectionContainer';
import FilledContainer from './core/FilledContainer';
import CoreTitle from './core/CoreTitle';
import CoreParagraph from './core/CoreParagraph';
import AliceCarousel from 'react-alice-carousel';
import { COLORS } from '../resources/colors';

const PROGRAMS = ['FullStack Web Dev', 'Python Dev & Data Scientist'];

const MENTORS_AVATAR = [
  { avatar: Mentor1, borderRadius: '0' },
  { avatar: Mentor2, borderRadius: '50%' },
];

const MENTORS_NAME = ['Cezar Gheorghiu', 'Alexandru Niculae'];

const MENTORS_DESCRIPTION = [
  `Sunt o persoană foarte sociabilă, harnică și ambițioasă, cu ani de
 experiență în dezvoltarea web. Sunt convins că nimic nu este imposibil
 atâta timp cât ieși din zona ta de confort.`,

  ` Cu o experiență de peste șase ani în domeniul programării, am navigat
 prin diverse arii precum computer vision, data science și chiar putin AI.
 Îmi place să vizualizez tot ceea ce creez și să înțeleg profund conceptele
 din spatele codului.`,
];

const MENTORS_PROGRAM = [
  'Full Stack Web Development',
  'Python Development &  DevOps Engineer',
];

const MENTORS_EXPERIENCE = ['10 ani + ', '7 ani + '];

const OurMentors = () => {
  const [selectedProgramIndex, setSelectedProgramIndex] = useState(null);
  const [triggerClick, setTriggerClick] = useState(false);

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const handleSelectProgram = index => {
    setSelectedProgramIndex(index);
  };

  useEffect(() => {
    const buttons = document.querySelectorAll('button');
    for (const trigger of buttons) {
      trigger.addEventListener('click', e => {
        setTriggerClick(true);
      });
    }

    setTriggerClick(false);
  }, []);

  const ChakraBox = chakra(motion.div, {
    shouldForwardProp: prop => shouldForwardProp(prop) || triggerClick,
  });

  const carouselItems = useMemo(
    () =>
      PROGRAMS.map((program, index) => (
        <Flex
          key={index}
          flexDirection="column"
          bg={COLORS.cardColor}
          width={selectedProgramIndex === null ? '240px' : '160px'}
          height={selectedProgramIndex === null ? '240px' : '180px'}
          padding={2}
          gap={2}
          justify="center"
          align="center"
          borderRadius="35px"
          direction="row"
          cursor="pointer"
          {...(selectedProgramIndex === null
            ? { className: 'simpleCard' }
            : {})}
          onClick={() => handleSelectProgram(index)}
        >
          <Box mt="10px">
            <img
              src={MENTORS_AVATAR[index].avatar}
              width={selectedProgramIndex === null ? '170px' : '125px'}
              alt="bg"
              style={{ borderRadius: MENTORS_AVATAR[index].borderRadius }}
            />
          </Box>

          <Text
            fontSize={selectedProgramIndex === null ? '17px' : '16px'}
            fontWeight={selectedProgramIndex === null ? 'bold' : 'none'}
          >
            {MENTORS_NAME[index]}
          </Text>
        </Flex>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProgramIndex]
  );

  return (
    <SectionContainer fullWidth sectionName={HEADERS.ourMentors}>
      <Box
        className="section"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gap={20}
        minH="800px"
      >
        <CoreTitle text="MENTORI" />
        <Box display="flex" justifyContent="center" alignItems="center">
          <CoreParagraph
            fontSize="18px"
            maxW="1200px"
            textAlign="center"
            text="Mentorii noștri sunt prezenți la fiecare etapă a parcursului tău educațional. Cu ani de experiență în software development, acești experți te vor
 ghida și te vor sprijini în fiecare moment al călătoriei tale de învățare."
          />
        </Box>

        {selectedProgramIndex === null ? (
          <Flex
            direction="column"
            maxWidth={{
              base: '100%',
              sm: '100%',
              md: '100%',
              lg: '90%',
              xl: '90%',
              '2xl': '90%',
            }}
            gap={20}
            alignSelf="center"
          >
            <Grid
              templateColumns={{
                base: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
                md: 'repeat(1, 1fr)',
                lg: 'repeat(2, 1fr)',
                xl: 'repeat(2, 1fr)',
                '2xl': 'repeat(2, 1fr)',
              }}
              gap={10}
            >
              {carouselItems}
            </Grid>
          </Flex>
        ) : (
          <Fade in={selectedProgramIndex !== null}>
            <Flex
              width="100%"
              flexWrap={{
                base: 'wrap',
                sm: 'wrap',
                md: 'wrap',
                lg: 'nowrap',
                xl: 'nowrap',
                '2xl': 'nowrap',
              }}
              flexDirection={{
                base: 'column',
                sm: 'column',
                md: 'column',
                lg: 'column',
                xl: 'row',
                '2xl': 'row',
              }}
              alignItems="center"
              justifyContent="center"
              gap={10}
            >
              <Container
                display="flex"
                alignItems="center"
                justifyContent="center"
                margin="0"
                padding="0"
                minHeight={{
                  base: '400px',
                  sm: '300px',
                  md: '300px',
                }}
              >
                <ChakraBox
                  animate={{
                    scale: [0.4, 1],
                  }}
                  transition={{
                    duration: 0.3,
                    ease: 'easeInOut',
                  }}
                >
                  <Flex
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    gap={4}
                    maxW="570px"
                    width="100%"
                    padding={0}
                    minHeight="200px"
                  >
                    <Text
                      fontSize={{
                        base: '30px',
                        sm: '25px',
                        md: '36px',
                        lg: '25px',
                        xl: '30px',
                        '2xl': '36px',
                      }}
                      fontWeight="bold"
                    >
                      {MENTORS_NAME[selectedProgramIndex]}
                    </Text>

                    <CoreParagraph
                      text={MENTORS_DESCRIPTION[selectedProgramIndex]}
                    />
                    <CoreParagraph
                      text={`Program de Training: ${MENTORS_PROGRAM[selectedProgramIndex]}`}
                      fontWeight="bold"
                    />
                    <CoreParagraph
                      text={`Experiență:  ${MENTORS_EXPERIENCE[selectedProgramIndex]}`}
                      fontWeight="bold"
                    />
                  </Flex>
                </ChakraBox>
              </Container>

              {isMobile ? (
                <div className="mentorsCarousel">
                  <AliceCarousel
                    infinite
                    dotsDisabled={true}
                    disableDotsControls
                    buttonsDisabled={true}
                    items={carouselItems}
                    duration={500}
                    paddingLeft="0"
                    paddingRight="0"
                    activeIndex={selectedProgramIndex}
                    onSlideChanged={e => setSelectedProgramIndex(e.item)}
                    renderPrevButton={() => (
                      <Button bg={COLORS.green}>
                        <ChevronLeftIcon />
                      </Button>
                    )}
                    renderNextButton={() => (
                      <Button bg={COLORS.green}>
                        <ChevronRightIcon />
                      </Button>
                    )}
                  />
                </div>
              ) : (
                <Flex gap={10}>
                  {PROGRAMS.map((program, index) => (
                    <Flex
                      key={index}
                      flexDirection="column"
                      bg={COLORS.cardColor}
                      width="160px"
                      padding={2}
                      gap={2}
                      justify="center"
                      align="center"
                      borderRadius="35px"
                      direction="row"
                      cursor="pointer"
                      {...(selectedProgramIndex === null
                        ? { className: 'simpleCard' }
                        : selectedProgramIndex === index
                        ? {
                            className: 'selectedCard',
                          }
                        : {})}
                      onClick={() => handleSelectProgram(index)}
                    >
                      <Box mt="10px">
                        <img
                          src={MENTORS_AVATAR[index].avatar}
                          width="125px"
                          alt="bg"
                          style={{
                            borderRadius: MENTORS_AVATAR[index].borderRadius,
                          }}
                        />
                      </Box>

                      <Text fontSize="16px">{MENTORS_NAME[index]}</Text>
                    </Flex>
                  ))}
                </Flex>
              )}
            </Flex>
          </Fade>
        )}
      </Box>

      {/* Google reviews */}
      <Box className="section">
        <FilledContainer>
          <CoreTitle text="RECENZIILE ABSOLVENȚILOR" mb="32" />
          <GoogleReviews />
        </FilledContainer>
      </Box>
    </SectionContainer>
  );
};

export default OurMentors;
